import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResponseWithMeta } from '../models/api';
import { PaginationEvent } from '../models/table';
import {
  EditUserPayload,
  NewUserPayload,
  Role,
  User,
  UserOnboarding,
} from '../models/user';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { translateAndSortRoles } from '../../helpers/translation.helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private errorService = inject(GlobalErrorHandlerService);
  private translateService = inject(TranslateService);

  getUserData(): Observable<User> {
    return this.http.get<User>(`${environment.supraApi}/v1/user/me`);
  }

  getAllUsers(
    pagination?: PaginationEvent
  ): Observable<ApiResponseWithMeta<User>> {
    const params = {
      limit: pagination?.pageSize.toString() || '10',
      page: pagination?.pageIndex ? (pagination.pageIndex + 1).toString() : '1',
      excludeCurrentUser: 'true',
    };
    return this.http.get<ApiResponseWithMeta<User>>(
      `${environment.supraApi}/v1/user/company/list`,
      { params }
    );
  }

  getAllRoles(): Observable<Role[]> {
    return this.http
      .get<Role[]>(`${environment.supraApi}/v1/role/list`)
      .pipe(
        switchMap(response =>
          translateAndSortRoles(response, this.translateService)
        )
      );
  }

  createUser(user: NewUserPayload): Observable<User> {
    return this.http.post<User>(
      `${environment.supraApi}/v1/user/company`,
      user
    );
  }

  editUser(userId: string, user: EditUserPayload): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/${userId}`,
      user
    );
  }

  changeUserStatus(
    userId: string,
    status: 'active' | 'inactive'
  ): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/status/${userId}/${status}`,
      {}
    );
  }

  updateUserRole(userId: string, roleSlug: string): Observable<string> {
    return this.http.put<string>(
      `${environment.supraApi}/v1/user/company/role/${userId}`,
      { roleSlug }
    );
  }

  deleteUser(userUid: string): Observable<string> {
    return this.http.delete<string>(
      `${environment.supraApi}/v1/user/company/delete/${userUid}`
    );
  }

  completeOnboarding(): Observable<string> {
    return this.http.post<string>(
      `${environment.supraApi}/v1/user/complete-onboarding`,
      {}
    );
  }

  startUserOnboarding(user: UserOnboarding): Observable<string> {
    return this.http.post<string>(
      `${environment.apiGatewayUrl}onboarding`,
      user
    );
  }

  async getUserRoles(): Promise<string[]> {
    try {
      const user = await fetchAuthSession();
      const groups = user.tokens?.idToken?.payload[
        'cognito:groups'
      ] as string[];
      return groups || [];
    } catch (error) {
      this.errorService.logError(error);
      return [];
    }
  }
}
